import './Main.css';
import { Navbar, Footer } from '../components/MainNav';
import { Link } from 'react-router-dom';

function App() {
  return (
    <>
      <Navbar></Navbar>

        <section className="hero">
            <div className="hero-slider" >
                <div className="hero-slide" style={{ backgroundImage: `url('/api/placeholder/1140/500')` }}>
                    <div className="hero-content">
                        <h1>개발자 도구</h1>
                        <p>개발자 도구과 함께 여러분의 서비스를 시작하세요.</p>
                        <Link to="/challenge/2024/dokdo">Go to Dokdo</Link>
                        {/* <a href="#" className="cta-button">시작하기</a> */}
                    </div>
                </div>
                <div className="hero-slide" style={{ backgroundImage: `url('/api/placeholder/1140/500')` }}>
                    <div className="hero-content">
                        <h1>혁신적인 가격</h1>
                        <p>어떠한 비용없이 개발자 도구를 이용해보세요.</p>
                        <a href="#" className="cta-button">더 알아보기</a>
                    </div>
                </div>
                <div className="hero-slide" style={{ backgroundImage: `url('/api/placeholder/1140/500')` }}>
                    <div className="hero-content">
                        <h1>개발자 블로그</h1>
                        <p>다른 개발자들의 블로그를 보고 공유하세요.</p>
                        <a href="#" className="cta-button">커뮤니티 참여</a>
                    </div>
                </div>
            </div>
            <button className="slider-control prev">&lt;</button>
            <button className="slider-control next">&gt;</button>
        </section>

        <section id="products" className="container" > 
            <h2>추천 제품</h2>
            <div className="products">
                <div className="product-card">
                    <h2>미리보기 이미지</h2>
                    <br />
                    <p>이미지 미리보기<br />url을 생성합니다.<br /><br />카카오톡에서 손쉽게<br />이미지를 공유해보세요.</p>
                    <a href="#" className="product-link" style={{ textAlign: 'center', display: 'block' }}>문서 보기</a>
                </div>
            </div>
        </section>
      <Footer></Footer>
    </>
  );
}

export default App;
