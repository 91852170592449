import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function CollapsibleExample() {
  return (
    <Navbar collapseOnSelect expand="lg" className="navbar">
      <Container>
        <Navbar.Brand href="#home">독도 Home</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Nav>
            <Nav.Link eventKey={1} href="#deets">독도가 우리땅인 이유</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">독도 가는길</Nav.Link>
            <Nav.Link eventKey={3} href="#memes">오는길</Nav.Link>
          </Nav>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;


