import Navbar from '../components/dokdo/navbar'
import Body from '../components/dokdo/body'
import "./Dokdo.css"

const DokdoPage = () => {
  return (
    <>
        <Navbar/>
        <Body/>
    </>
  );
};

export default DokdoPage;
