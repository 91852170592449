import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './pages/Main'; 
import DokdoPage from './pages/Dokdo';

function App() {
  return (
				<Routes>
					<Route path="/" element={<Main />}></Route>
					<Route path="/challenge/2024/dokdo" element={<DokdoPage />}></Route>
        </Routes>
  );
}

export default App;
