function Navbar() {
    return (
      <nav>
        <div className="container">
          <div className="logo">junsung Dev</div>
          <ul>
            <li><a href="#docs">문서</a></li>
            <li><a href="#notices">공지사항</a></li>
            <li><a href="#login">로그인</a></li>
          </ul>
        </div>
      </nav>
    );
  }
  
  function Footer() {
    return (
      <footer >
        <div className="container">
          <p>&copy; Junsung Developer. All rights reserved.</p>
          <div className="footer-links">
            <a href="#">서비스 약관</a>
            <a href="#">운영정책</a>
          </div>
        </div>
      </footer>
    );
  }
  
  export { Navbar, Footer };
  